<template>
    <div class="room-selection">
        <h1>Select a Room</h1>
        <h2>Normal rooms</h2>
        <button @click="goToRoom('GameRoom')">Game Room</button>
        <button @click="goToRoom('AllRoom')">All Room</button>
        <button @click="goToRoom('AnimeRoom')">Anime Room</button>
        <h2>Private rooms</h2>
        <button @click="goToRoom('CreatePrivateRoom')">Host</button>
        <button @click="goToRoom('JoinPrivateRoom')">Join</button>
        <h2>Settings</h2>
        <button @click="goToRoom('UserSettings')">Settings</button>
    </div>
</template>

<script>
    export default {
        methods: {
            goToRoom(roomName) {
                console.log(roomName);
                this.$router.push({ name: roomName });
            },
        },
    };
</script>

<style scoped>
    .room-selection {
        /* Add styles here */
    }
</style>
