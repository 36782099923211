<template>
    <div class="user-settings">
        <h1>User Settings</h1>
        <div v-if="games.length">
            <form @submit.prevent="saveSelectedGames">
                <div v-for="game in games" :key="game.id" class="game-option">
                    <!-- Image de la jaquette -->
                    <img v-if="game.cover_url" :src="game.cover_url" :alt="game.name" class="game-cover" />
                    <!-- Checkbox et �tiquette du jeu -->
                    <input type="checkbox"
                           :id="'game_' + game.id"
                           :value="game.id"
                           v-model="selectedGames" />
                    <label :for="'game_' + game.id">{{ getFirstGameName(game.name) }}</label>
                </div>
                <button type="submit">Save</button>
            </form>
        </div>
        <div v-else>
            <p>Loading games...</p>
        </div>
        <button @click="backToMenu" class="back-button">Back to Menu</button>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                games: [],
                selectedGames: [],
            };
        },
        created() {
            this.fetchGames();
        },
        methods: {
            async fetchGames() {
                try {
                    // R�cup�rer le nom d'utilisateur depuis le cookie
                    const username = this.getCookie('username');
                    if (!username) {
                        console.error('Nom d\'utilisateur non trouv� dans les cookies.');
                        return;
                    }

                    // R�cup�rer la liste des jeux
                    const response = await axios.get('/api/games_and_id');
                    this.games = response.data;

                    // R�cup�rer les jeux d�j� s�lectionn�s par l'utilisateur
                    const selectedResponse = await axios.get('/api/user_selected_games', {
                        params: {
                            username: username
                        }
                    });
                    this.selectedGames = selectedResponse.data;

                } catch (error) {
                    console.error('Erreur lors de la r�cup�ration des jeux:', error);
                }
            },
            getFirstGameName(game) {
                return game.split(',')[0]; // Retourne uniquement la partie avant la premi�re virgule
            },
            async saveSelectedGames() {
                try {
                    // R�cup�rer le nom d'utilisateur depuis le cookie
                    const username = this.getCookie('username');
                    if (!username) {
                        alert('Nom d\'utilisateur non trouv� dans les cookies.');
                        return;
                    }

                    // Effectuer la requ�te POST avec le nom d'utilisateur
                    await axios.post('/api/user_igdb_lists', {
                        username: username,
                        game_ids: this.selectedGames,
                    });
                    alert('Games saved successfully!');
                } catch (error) {
                    console.error('Erreur lors de l\'enregistrement des jeux:', error);
                    alert('An error occurred while saving your games.');
                }
            },
            getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) {
                    return parts.pop().split(';').shift();
                }
            },
            backToMenu() {
                this.$router.push({ name: 'RoomSelection' }); // Assurez-vous que la route est bien configur�e
            },
        },
    };
</script>

<style scoped>
    .user-settings {
        /* Ajoutez des styles selon vos besoins */
    }

    .game-option {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    .game-cover {
        max-width: 100px; /* Limitez la largeur des images de couverture */
        height: auto;
        margin-right: 15px; /* Ajoutez un espace entre l'image et le nom du jeu */
    }
</style>
