import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/LoginView.vue'
import Register from '../views/RegisterView.vue'
import GameRoom from '../views/GameRoomView.vue'
import RoomSelection from '../views/RoomSelectionView.vue'
import AnimeRoom from '../views/AnimeRoomView.vue'
import AllRoom from '../views/AllRoomView.vue'
import CreatePrivateRoomView from '../views/CreatePrivateRoomView.vue';
import JoinPrivateRoomView from '../views/JoinPrivateRoomView.vue';
import PrivateRoomView from '../views/PrivateRoomView.vue';
import UserSettings from '../views/UserSettingsView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
    },
    {
    path: '/login',
    name: 'login',
    component: Login
    },
    {
    path: '/register',
    name: 'register',
    component: Register
    },
    {
    path: '/RoomSelection',
    name: 'RoomSelection',
    component: RoomSelection
    },
    {
    path: '/settings',
    name: 'UserSettings',
    component: UserSettings,
    },
    {
    path: '/GameRoom',
    name: 'GameRoom',
    component: GameRoom
    },
    {
    path: '/AllRoom',
    name: 'AllRoom',
    component: AllRoom
    },
    {
    path: '/AnimeRoom',
    name: 'AnimeRoom',
    component: AnimeRoom
    },
    {
        path: '/create-room',
        name: 'CreatePrivateRoom',
        component: CreatePrivateRoomView
    },
    {
        path: '/join-room',
        name: 'JoinPrivateRoom',
        component: JoinPrivateRoomView
    },
    {
        path: '/room/:roomId',  // :roomId est un paramètre dynamique
        name: 'PrivateRoom',
        component: PrivateRoomView,
        props: true  // Permet au composant de recevoir :roomId en tant que prop
    },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
