<template>
    <div class="register">
        <h1>Registration</h1>
        <form @submit.prevent="register">
            <div class="form-group">
                <label for="username">Username</label>
                <input type="text"
                       id="username"
                       v-model="user.username"
                       required />
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password"
                       id="password"
                       v-model="user.password"
                       required />
            </div>
            <button type="submit">Register</button>
        </form>
    </div>
</template>

<script>
    import axios from 'axios';

    const apiClient = axios.create({
        baseURL: 'https://avgmq.net',
        withCredentials: true, // Enable sending cookies over CORS
        headers: {
            'Content-Type': 'application/json',
            // Vous pouvez ajouter ici des en-têtes ou des configurations supplémentaires
        },
    });

    export default {
        data() {
            return {
                user: {
                    username: '',
                    password: '',
                },
            };
        },
        methods: {
            async register() {
                try {
                    const response = await apiClient.post('/api/register', this.user);
                    // Gérer la réponse, par exemple stocker le JWT et rediriger l'utilisateur
                    localStorage.setItem('access_token', response.data.access_token);
                    this.$router.push({ name: 'RoomSelection' }); // ou vers une autre route
                    document.cookie = "username=" + this.user.username + ";path=/;max-age=86400"; // Expire après 1 heure
                } catch (error) {
                    console.error(error);
                    // Gérer les erreurs, par exemple afficher un message à l'utilisateur
                }
            },
        },
    };
</script>


<style scoped>
    .register {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 300px;
        margin: auto; /* Remplacera 'margin: 50px auto' pour centrer dans la vue */
        padding: 50px;
        padding-right: 70px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.2);
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .form-group {
        margin-bottom: 10px;
        width: 100%; /* Pour que les éléments s'étendent à la largeur du conteneur */
    }

    label {
        display: block;
        margin-bottom: 5px;
        margin-left: 20px;
    }

    h1 {
        margin-left: 20px;
        margin-bottom: 50px;
    }

    input[type='text'],
    input[type='email'],
    input[type='password'] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: #fff;
        color: #333;
        margin-right: 50px;
    }

    button {
        margin-left: 45px;
        border: none;
        background-color: #42b983;
        color: white;
        cursor: pointer;
        border-radius: 4px;
    }

        button:hover {
            background-color: #555;
        }
</style>
