<template>
    <div class="room-container">
        <h1>Room: {{ roomDetails.name }}</h1>
        <div>
            <p>Room ID: {{ roomId }}</p>
            <p>Number of songs: {{this.currentSong}}/{{ roomDetails.numberOfSongs }}</p>
            <p>Include Games: {{ roomDetails.includeGames ? 'Yes' : 'No' }}</p>
            <p>Include Animes: {{ roomDetails.includeAnimes ? 'Yes' : 'No' }}</p>
            <p>Use Game List: {{ roomDetails.includeIGDBList ? 'Yes' : 'No' }}</p>

            <!-- Interactions -->
            <button v-if="showPlayButton && roomDetails.isCreator" @click="playMusic" class="play-button">Play</button>
            <button v-if="showPlayButton && roomDetails.isCreator" @click="openSettings" class="settings-button">Settings</button>
        </div>
        <div class="top-bar">
            <div class="scoreboard">
                <h2>Scores</h2>
                <ul>
                    <li v-for="(data, username) in userScores" :key="username" :class="userClasses[username]">
                        {{ username }}: {{ data.score }}
                        <span v-if="data.isFastest" class="crown">👑</span>
                    </li>
                </ul>
            </div>
            <div class="volume-control">
                <label for="volumeSlider">Volume:</label>
                <input type="range" id="volumeSlider" min="0" max="100" v-model="volume" @input="updateVolume" class="volume-slider" />
            </div>
        </div>
        <div class="ping-display">
            <p>Ping: {{ ping }} ms</p>
        </div>
        <div v-if="messages.length" class="messages">
            <ul>
                <li v-for="message in messages" :key="message.id">
                    <template v-if="message.username">
                        <strong>{{ message.username }}</strong> :&nbsp;
                    </template>
                    {{ message.content }}
                </li>
            </ul>
            <form @submit.prevent="sendMessage">
                <input v-model="newMessage" type="text" placeholder="Write your message..." />
            </form>
        </div>
        <audio ref="audioPlayer" id="audioPlayer1" style="display: none;" controls>
            <source :src="audioSource" type="audio/mpeg">
            Votre navigateur ne supporte pas l'élément audio.
        </audio>
        <div class="response" v-if="remainingTime <= 0">
            <h2>Game/Anime details:</h2>
            <h3>Title: {{ currentGame }}</h3>
            <img v-if="boxArtUrl" :src="boxArtUrl" alt="Game Cover Art" class="box-art" />
            <h3>Song: {{ music_title }}</h3>
            <h3>Composer: {{ composer }}</h3>
            <div>
                <h3 v-if="fastestTime">Fastest Response: {{ fastestTime }}</h3>
                <h3 v-if="fastestResponseForGame">Fastest Response Ever Recorded: {{ fastestResponseForGame }}</h3>
            </div>
            <div v-if="waitingToStart" class="waiting-message">
                <p>Preparing to play the next song...</p>
                <p>Starting in {{ Math.ceil(waitingTime) }} seconds...</p>
            </div>
        </div>
        <div class="timer" v-else>
            Remaining time : {{ Math.floor(remainingTime) }} seconds
        </div>
        <input type="text"
               class="inputResponse"
               :disabled="!canUserRespond()"
               ref="inputElement"
               v-model="search"
               @input="filterGames"
               @keydown.down.prevent="onArrowDown"
               @keydown.up.prevent="onArrowUp"
               @keyup.enter="validateInput"
               @keydown.tab.prevent="onTab"
               placeholder="Enter a game...">

        <ul class="sugest" v-if="filteredGames.length">
            <li v-for="(gameName, index) in filteredGames"
                :key="index"
                :class="{ 'selected': index === selectedIndex }"
                @click="selectGame(gameName)"
                @mouseenter="() => selectedIndex = index">
                {{ gameName }}
            </li>
        </ul>
        <div class="try">
            Remaining tries : {{ 3-responses }}
        </div>
        <div class="top_scorers" v-if="isFinished">
            <h2>Winner(s):</h2>
            <ul>
                <li v-for="scorer in topScorers" :key="scorer.username">
                    {{ scorer.username }} with a score of {{ scorer.score }}
                </li>
            </ul>
        </div>

        <!-- Settings Modal -->
        <div v-if="showSettingsModal" class="settings-modal">
            <div class="settings-content">
                <h2>Room Settings</h2>
                <label for="numberOfSongs">Number of Songs:</label>
                <input type="number" id="numberOfSongs" v-model="roomDetails.numberOfSongs" min="1" />

                <div>
                    <input type="checkbox" id="includeGames" v-model="roomDetails.includeGames" />
                    <label for="includeGames">Include Games</label>
                </div>

                <div>
                    <input type="checkbox" id="includeAnimes" v-model="roomDetails.includeAnimes" />
                    <label for="includeAnimes">Include Animes</label>
                </div>

                <div>
                    <input type="checkbox" id="includeIGDBList" v-model="roomDetails.includeIGDBList" />
                    <label for="includeIGDBList">Use Game List</label>
                </div>

                <button @click="saveSettings">Save Settings</button>
                <button @click="closeSettings">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import io from 'socket.io-client';

    const apiClient = axios.create({
        baseURL: 'https://avgmq.net',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    export default {
        data() {
            return {
                roomId: this.$route.params.roomId,
                roomDetails: {},
                clockOffset: 0,  // Décalage entre le client et le serveur
                isLoading: true,
                socket: null,
                messages: [],
                newMessage: '',
                search: '',
                games: [],
                ping: 0,
                waitingToStart: false,
                waitingTime: 0,
                filteredGames: [],
                selectedIndex: -1,
                currentGame: "",
                boxArtUrl: "",
                userScore: 0,
                userScores: {},
                audioSource: '',
                audioDuration: 45, // durée par défaut pour jouer l'audio
                audioTimer: null,
                responses: 0,
                volume: 100,
                remainingTime: 45, // Initialisation à 45 secondes
                startTime: null,   // Temps de départ pour calculer la réponse
                responseTime: null, // Temps pris par l'utilisateur pour répondre
                responseTimes: [], // Stocke les temps de réponse
                fastestTime: null,
                fastestResponseForGame: null,
                username: "",
                alreadyFastest: false,
                showPlayButton: true,
                isFinished: false,
                topScorers: [],
                currentSong: 1,
                composer: "Unknown",
                music_title: "Unknown",
                showSettingsModal: false
            };
        },
        computed: {
            userClasses() {
                const classes = {};
                for (const [username, data] of Object.entries(this.userScores)) {
                    let className = '';
                    if (data.hasFound) {
                        className = 'found';
                    } else if (data.attemptsLeft === 0) {
                        className = 'failed';
                    } else {
                        className = 'unfound';
                    }
                    classes[username] = className;
                }
                return classes;
            }
        },
        created() {
            this.username = this.getUsernameFromCookie()
            this.initializeWebSocket();

            this.initializeRoomData();

           
            this.socket.on('settings_updated', (data) => {
                if (data.room_id === this.roomId) {
                    console.log('Room settings updated, reloading...');
                    this.fetchRoomDetails(); 
                }
            });

            this.socket.on('play_audio', this.handlePlayAudio);
            this.socket.on('prepare_to_play', this.handlePrepareToPlay);
            this.socket.on('sync_audio', this.handleSyncAudio);
            this.socket.on('score_update', () => {
                this.fetchScores();
            });
            this.socket.on('finished', () => {
                this.setPlay();
            });
            this.socket.on('details_set', () => {
                this.fetchGames();
            });
            this.socket.on('update_currentSong', (data) => {
                this.currentSong = data.index;
            });
            window.addEventListener('beforeunload', () => {
                if (this.socket) {

                    this.logout();
                }
                sessionStorage.setItem('isPageRefreshed', 'true');
            });
            window.addEventListener('popstate', this.handleBackButton);
        },
        mounted() {
            if (sessionStorage.getItem('isPageRefreshed')) {
                // Supprimez la marque indiquant que la page a été rafraîchie
                sessionStorage.removeItem('isPageRefreshed');

                // Redirigez l'utilisateur vers la page d'accueil
                this.$router.push('/');
            }

        },
        methods: {
            openSettings() {
                this.showSettingsModal = true;
            },
            closeSettings() {
                this.showSettingsModal = false;
            },
            saveSettings() {
                apiClient.post(`/api/update_room_settings/${this.roomId}?username=${this.username}`, {
                    numberOfSongs: this.roomDetails.numberOfSongs,
                    includeGames: this.roomDetails.includeGames,
                    includeAnimes: this.roomDetails.includeAnimes,
                    includeIGDBList: this.roomDetails.includeIGDBList
                })
                    .then(response => {
                        console.log('Settings updated successfully:', response.data);
                        this.closeSettings();
                    })
                    .catch(error => {
                        console.error('Error updating settings:', error);
                    });
            },
            handleEndOfGame() {
                this.fetchFastestTimeInSession();
                setTimeout(() => {
                    this.socket.emit('client_ready', { room_id: this.roomId });
                    
                }, 5000); 
                
                
            },
            initializeWebSocket() {
                this.socket = io('https://avgmq.net', { secure: true });

                this.socket.on('server_time', (data) => {
                    const clientTime = Date.now(); // Temps actuel du client (ms)
                    this.clockOffset = clientTime - data.server_time; // Décalage client-serveur
                    console.log(`Clock offset: ${this.clockOffset} ms`);
                });

                this.socket.on('connect', () => {
                    console.log('Connected to the game server');
                    this.socket.emit('joinPrivateRoom', { username: this.username, room: this.roomId });

                    setInterval(() => {
                        const sentTime = Date.now(); // Temps d'envoi du ping (ms)
                        this.socket.emit('ping_request', { sent_time: sentTime });
                    }, 5000);

                    this.socket.on('ping_response', (data) => {
                        // Latence corrigée = Latence brute - Décalage d'horloge
                        const correctedLatency = data.latency - this.clockOffset;
                        this.ping = Math.max(0, Math.round(correctedLatency)); // Évite les valeurs négatives
                        console.log(`Corrected Latency: ${this.ping} ms`);
                    });

                });
                this.socket.on('message', (message) => {
                    this.messages.push({
                        id: message.id,
                        content: message.content,
                        username: message.username
                    });
                });

                this.socket.on('disconnect', () => {
                    this.logout();
                    console.log('Disconnected from the game server');
                });
            },
            async initializeRoomData() {
                try {
                    await this.fetchRoomDetails(); // Attendre que fetchRoomDetails soit terminé
                    this.fetchGames(); // Appeler fetchGames après fetchRoomDetails
                } catch (error) {
                    console.error('Error initializing room data:', error);
                }
            },
            async fetchRoomDetails() {
                try {
                    const response = await apiClient.get(`/api/room_details/${this.roomId}?username=${this.username}`);
                    this.roomDetails = response.data; // Manipuler la réponse comme nécessaire
                } catch (error) {
                    console.error('Error fetching room details:', error); // Gérer les erreurs
                }
            },
            fetchScores() {
                const roomId = this.roomId; // Assurez-vous que roomId est disponible dans votre composant
                apiClient.get(`/api/get_scores/${roomId}`)
                    .then(response => {
                        this.userScores = response.data; // Stocker les scores dans une donnée réactive
                    })
                    .catch(error => {
                        console.error('Error fetching scores:', error);
                        // Gestion des erreurs, par exemple montrer un message à l'utilisateur
                    });
            },
            setPlay() {
                apiClient.get(`/api/get_top_scorers/${this.roomId}`)
                    .then(response => {
                        this.isFinished = true;
                        this.topScorers = response.data;
                        this.showPlayButton = true;
                    })
                    .catch(error => {
                        console.error('Error getting top scorers:', error); // Handle errors
                    });


            },
            playMusic() {
                this.showPlayButton = false;
                this.isFinished = false;
                this.topScorers = [];
                this.alreadyFastest = false;


                for (const username in this.userScores) {
                    this.userScores[username]['score'] = 0;
                    this.userScores[username]['hasFound'] = false;
                    this.userScores[username]['attemptsLeft'] = 3;
                    this.userScores[username]['isFastest'] = false;
                    this.userScores[username]['responseTime'] = null
                }

                apiClient.post(`/api/set_scores/${this.roomId}`, { scores: this.userScores, room: this.roomId, username: this.username, relaunch: true })
                    .then(response => {
                        console.log('Scores updated successfully:', response.data);
                    })
                    .catch(error => {
                        console.error('Error updating scores:', error);
                    });

                this.socket.emit('play', { room_id: this.roomId, numberOfSongs: this.roomDetails.numberOfSongs, games: this.roomDetails.includeGames, animes: this.roomDetails.includeAnimes, IGDB: this.roomDetails.includeIGDBList });
            },
            fetchFastestTimeInSession() {
                if (this.userScores && Object.keys(this.userScores).length > 0) {
                    // Find the fastest user based on response time
                    const fastestUser = Object.entries(this.userScores)
                        .filter(([, data]) => data.responseTime !== null && data.responseTime !== undefined) // Filter valid response times
                        .sort(([, a], [, b]) => a.responseTime - b.responseTime)[0]; // Get the fastest

                    if (fastestUser) {
                        const [username, data] = fastestUser;
                        this.fastestTime = `${username}: ${data.responseTime.toFixed(3)} seconds`;
                    } else {
                        this.fastestTime = "No fastest response in this session yet.";
                    }
                } else {
                    this.fastestTime = "No scores available in this session yet.";
                }
            },



            // Méthode pour récupérer la réponse la plus rapide enregistrée dans la base de données
            async fetchFastestResponse(gameName) {
                try {
                    const encodedGameName = encodeURIComponent(gameName);
                    const response = await apiClient.get(`/api/get_fastest_response/${encodedGameName}`);
                    if (response.status === 200) {
                        const { username, response_time } = response.data;
                        this.fastestResponseForGame = `${username}: ${response_time.toFixed(3)} seconds`;
                    } else {
                        this.fastestResponseForGame = "No fastest response recorded for this game.";
                    }
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        this.fastestResponseForGame = "No fastest response recorded for this game.";
                    } else {
                        console.error('Error fetching fastest response:', error);
                        this.fastestResponseForGame = "Error fetching fastest response.";
                    }
                }
            },




            updateVolume() {
                // Mettez à jour le volume de l'élément audio
                let player = this.$refs.audioPlayer;
                if (player) {
                    player.volume = this.volume / 100; // Le volume est un nombre entre 0 (silencieux) et 1 (volume maximum)
                }
            },
            handlePlayAudio(data) {
                this.currentGame = data.game_name;
                this.fetchFastestResponse(this.currentGame);
                this.fastestTime = "No time recorded";
                this.audioSource = data.audio_url;
                this.remainingTime = data.duration;
                this.startTime = performance.now();
                this.responses = 0;
                this.alreadyFastest = false;
                this.boxArtUrl = data.box_art_url;
                this.composer = data.composer;
                this.music_title = data.music_title;

                for (const username in this.userScores) {
                    this.userScores[username]['hasFound'] = false;
                    this.userScores[username]['attemptsLeft'] = 3;
                    this.userScores[username]['isFastest'] = false;
                    this.userScores[username]['responseTime'] = null
                }

                apiClient.post(`/api/set_scores/${this.roomId}`, { scores: this.userScores, room: this.roomId, username: this.username, relaunch: false })
                    .then(response => {
                        console.log('Scores updated successfully:', response.data);
                    })
                    .catch(error => {
                        console.error('Error updating scores:', error);
                    });

                this.$refs.audioPlayer.load();
                this.$refs.audioPlayer.currentTime = data.start_time;
                this.$refs.audioPlayer.play().then(() => {
                    if (this.audioTimer) {
                        clearInterval(this.audioTimer); // Nettoyer le timer précédent s'il existe
                    }
                    this.audioTimer = setInterval(() => {
                        if (this.remainingTime > 0) {
                            this.remainingTime -= 1;
                        } else {
                            clearInterval(this.audioTimer);
                            this.responses = 3; // Fin du temps
                            this.$refs.audioPlayer.pause();
                            this.handleEndOfGame();
                        }
                    }, 1000); // Décrémente le compteur toutes les secondes
                }).catch(error => {
                    console.error("Error playing audio:", error);
                });
          
            },
            handlePrepareToPlay(data) {
                const { audio_url, start_time, play_at, game_name, music_title, composer, duration } = data;

                this.currentGame = game_name;
                this.fetchFastestResponse(this.currentGame);
                this.fastestTime = "No time recorded";
                this.audioSource = audio_url;
                this.music_title = music_title;
                this.composer = composer;
                this.remainingTime = duration; // Reset the duration for the new audio
                this.startTime = performance.now(); // Record the time for response tracking
                this.responses = 0;
                this.boxArtUrl = data.box_art_url;
                this.alreadyFastest = false;

                // Reset the scores for the current game
                for (const username in this.userScores) {
                    this.userScores[username]['hasFound'] = false;
                    this.userScores[username]['attemptsLeft'] = 3;
                    this.userScores[username]['isFastest'] = false;
                    this.userScores[username]['responseTime'] = null;
                }

                // Load the audio
                const player = this.$refs.audioPlayer;
                if (player) {
                    player.src = audio_url;
                    player.load();

                    // Calculate the delay to start the audio
                    const currentTime = Date.now() / 1000; // Current time in seconds
                    const delay = play_at + this.clockOffset - currentTime;

                    if (delay > 0) {
                        this.waitingToStart = true;
                        this.waitingTime = delay;
                        console.log(`Audio will start in ${delay} seconds`);

                        const countdownInterval = setInterval(() => {
                            this.waitingTime -= 1;
                            if (this.waitingTime <= 0) {
                                clearInterval(countdownInterval);
                                this.waitingToStart = false;
                            }
                        }, 1000);
                    


                        setTimeout(() => {
                            this.waitingToStart = false;
                            player.currentTime = start_time; // Start at the specified time
                            player.play().then(() => {
                                console.log('Audio is playing');
                                this.startAudioTimer(duration); // Start the countdown timer
                            }).catch((error) => {
                                console.error('Error playing audio:', error);
                            });
                        }, delay * 1000); // Convert delay to milliseconds
                    } else {
                        console.error("Audio started late, playing immediately.");
                        player.currentTime = start_time;
                        player.play();
                        this.startAudioTimer(duration);
                    }
                }
            },

            startAudioTimer(duration) {
                // Start the timer to track the remaining time
                this.remainingTime = duration;
                if (this.audioTimer) {
                    clearInterval(this.audioTimer); // Clear any existing timer
                }
                this.audioTimer = setInterval(() => {
                    if (this.remainingTime > 0) {
                        this.remainingTime -= 1;
                    } else {
                        clearInterval(this.audioTimer);
                        this.responses = 3; // End the time
                        this.$refs.audioPlayer.pause();
                    }
                }, 1000); // Update every second
            },
        
            handleSyncAudio(data) {
                let remainingTime = data.duration - data.elapsed_time;
                if (remainingTime > 0) {
                    // Démarrez la lecture de l'audio avec le temps restant
                    this.playTimer(data.elapsed_time, data.duration);
                }
            },
            onArrowDown() {
                if (this.selectedIndex < this.filteredGames.length - 1) {
                    this.selectedIndex++;
                }
            },
            onArrowUp() {
                if (this.selectedIndex > 0) {
                    this.selectedIndex--;
                }
            },
            onTab() {
                // Assurez-vous que selectedIndex est dans la plage correcte
                if (this.selectedIndex >= 0 && this.selectedIndex < this.filteredGames.length) {
                    this.selectGame(this.filteredGames[this.selectedIndex]);
                }
            },
            validateInput() {
                // La méthode checkGameSelection est appelée avec la valeur actuelle de l'input
                this.checkGameSelection(this.search);
                // Réinitialiser le champ de recherche
                this.search = '';
                // Fermez la liste des suggestions
                this.filteredGames = [];
                // Réinitialisez l'index sélectionné
                this.selectedIndex = -1;
            },
            selectGame(gameName) {
                this.search = gameName;  // Met à jour le champ de recherche avec le nom du jeu sélectionné
                this.filteredGames = [];  // Efface les suggestions
                this.selectedIndex = -1;  // Réinitialise l'index sélectionné
            },
            checkGameSelection(selectedGame) {
                const inputElement = this.$refs.inputElement;
                if (this.responses < 3) {
                    if (selectedGame.toLowerCase() === this.currentGame.toLowerCase()) {
                        this.responses = 3;
                        this.userScores[this.username]['hasFound'] = true;
                        this.userScores[this.username]['attemptsLeft'] = 0;
                        inputElement.style.backgroundColor = 'green';

                        // Calculer le temps de réponse précis en millisecondes
                        const responseTime = (performance.now() - this.startTime) / 1000; // Temps en secondes avec millisecondes

                        this.userScores[this.username]['responseTime'] = responseTime;

                        this.fetchFastestTimeInSession();

                        // Emit data to backend to check and update the fastest response
                        apiClient.post('/api/update_fastest_response', {
                            game_name: this.currentGame,
                            username: this.username,
                            response_time: responseTime
                        })
                            .then(() => {
                                console.log('Fastest response checked/updated in the database.');
                            })
                            .catch(error => {
                                console.error('Error updating fastest response:', error);
                            });

                        setTimeout(() => {
                            inputElement.style.backgroundColor = '';
                        }, 500);

                        this.updateUserScore(this.username);
                    } else {
                        this.responses++;
                        this.userScores[this.username]['attemptsLeft'] -= 1;

                        inputElement.style.backgroundColor = 'red';

                        setTimeout(() => {
                            inputElement.style.backgroundColor = '';
                        }, 500);

                        apiClient.post(`/api/set_scores/${this.roomId}`, { scores: this.userScores, room: this.roomId, username: this.username, relaunch: false })
                    }
                }
            },



            canUserRespond() {
                return this.userScores[this.username] && this.userScores[this.username]['attemptsLeft'] > 0;
            },

            updateUserScore(username) {
                if (this.userScores[username]) {
                    this.userScores[username]['score']++;
                } else {
                    this.userScores[username]['score'] = 1;
                }


                // Notifiez le serveur ou les autres utilisateurs si nécessaire
                apiClient.post(`/api/set_scores_up/${this.roomId}`, { scores: this.userScores, room: this.roomId, username: this.username })
                    .then(response => {
                        console.log('Scores updated successfully:', response.data);
                    })
                    .catch(error => {
                        console.error('Error updating scores:', error);
                    });

            },

            getUsernameFromCookie() {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; username=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
                return "";
            },
            fetchGames() {
                let apiEndpoint;
                console.log('room details', this.roomDetails);
                if (this.roomDetails.includeGames && this.roomDetails.includeAnimes) {
                    apiEndpoint = 'api/all';
                } else if (this.roomDetails.includeAnimes) {
                    apiEndpoint = 'api/animes';
                } else {
                    apiEndpoint = 'api/games';
                }

                if (apiEndpoint) {
                    this.fetchData(apiEndpoint);
                }
            },

            fetchData(endpoint) {
                apiClient.get(endpoint)
                    .then((response) => {
                        if (Array.isArray(response.data)) {
                            const gameNames = response.data
                                .map(game => typeof game === 'string' ? game.split(',')[0] : game[0].split(',')[0])  // Assume les noms peuvent venir dans un tableau ou comme chaîne
                                .filter((value, index, self) => self.indexOf(value) === index)  // Filtrer les doublons
                                .sort();
                            this.games = gameNames;
                        } else {
                            console.error('Les données reçues ne sont pas un tableau', response.data);
                        }
                    })
                    .catch((error) => {
                        console.error(`Erreur lors de la récupération depuis ${endpoint}`, error);
                        // Afficher un message d'erreur à l'utilisateur si nécessaire
                    });
            },
            normalizeString(str) {
                return str.normalize("NFD").replace(/[̀-ͯ]/g, "");
            },

            filterGames() {
                if (this.search) {
                    const normalizedSearch = this.normalizeString(this.search.toLowerCase());
                    this.filteredGames = this.games.filter((gameName) => {
                        const normalizedGameName = this.normalizeString(gameName.toLowerCase());
                        return normalizedGameName.includes(normalizedSearch);
                    });
                } else {
                    this.filteredGames = [];
                }
            },

            playTimer(elapsedTime, duration) {
                this.remainingTime = duration - elapsedTime;

                if (this.audioTimer) {
                    clearInterval(this.audioTimer); // Nettoyer le timer précédent s'il existe
                }

                this.audioTimer = setInterval(() => {
                    if (this.remainingTime > 0) {
                        this.remainingTime -= 1;
                    } else {
                        clearInterval(this.audioTimer);
                        this.$refs.audioPlayer.pause();

                        // Appeler handleEndOfGame lorsque le timer atteint zéro
                        this.handleEndOfGame();
                    }
                }, 1000); // Décrémente le compteur toutes les secondes
            },

            extractVideoIdFromUrl(url) {
                // Utilisez une RegExp pour extraire l'ID de la vidéo
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = url.match(regExp);

                if (match && match[2].length == 11) {
                    return match[2];
                } else {
                    // L'URL n'est pas dans un format attendu pour une vidéo YouTube
                    console.error('Invalid YouTube URL:', url);
                    return null;
                }
            },
            sendMessage() {
                if (this.newMessage.trim()) {
                    this.socket.emit('newMessage', { content: this.newMessage, username: this.username });
                    this.newMessage = ''; // Reset the input field after sending
                }
            },
            handleBackButton() {
                // Appeler la déconnexion
                this.logout(() => {
                    console.log('User logged out');
                    // Faites d'autres choses ici si nécessaire, comme rediriger l'utilisateur
                });
            },
            logout() {
                this.socket.emit('manual_disconnect', { username: this.username, room: this.roomId }, () => {
                    console.log('Logout and disconnect confirmed');
                    this.$router.push('/RoomSelection');

                });
            }

        }
    };
</script>

<style scoped>
    .room-container {
        padding: 20px;
        margin: auto;
        max-width: 600px;
    }

    .response {
        background-color: rgba(0, 0, 0, 0.7); /* Fond noir transparent */
        color: white;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 10px; /* Espacement entre les blocs */
    }

    button {
        margin: 10px;
        padding: 8px 16px;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

        button:hover {
            background-color: #45a049;
        }

    .logout-button {
        background-color: #f44336; /* Rouge pour le bouton de déconnexion */
    }

        .logout-button:hover {
            background-color: #d32f2f;
        }

    .game-room {
        position: relative;
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        text-align: center;
    }

    .messages {
        position: fixed;
        right: 0;
        top: 0;
        width: 300px; /* Définir une largeur pour la zone de message */
        height: 100vh; /* Prendre toute la hauteur de la vue */
        overflow-y: scroll; /* Permettre le défilement si le contenu est trop long */
        padding: 20px;
        background-color: rgb(18, 18, 18, 0.7); /* Choisir une couleur de fond */
        border-left: 1px solid #ccc; /* Ajouter une bordure à gauche */
        box-shadow: -2px 0px 5px rgba(0,0,0,0.1); /* Ajouter une ombre pour mieux séparer du reste du contenu */
    }

        .messages li {
            margin-bottom: 10px;
            background-color: rgb(36, 36, 36, 0.7);
            color: white;
            padding: 10px;
            border-radius: 5px;
            word-wrap: break-word;
        }

        .messages input, .inputResponse {
            border: 1px solid #ccc;
            color: white;
        }

    .top_scorers ul {
        list-style-type: none; /* Removes bullet points */
        padding: 0; /* Removes padding, especially important for Chrome, Safari */
        margin: 0; /* Adjusts margin for a clean alignment */
    }


    .top_scorers li {
        color: white;
    }

    ::placeholder {
        color: white;
    }

    input {
        margin-top: 20px;
        padding: 10px;
        width: calc(100% - 22px);
        margin-bottom: 10px;
    }

    .selected {
        background-color: #e0e0e0; /* ou toute autre couleur de votre choix */
    }

    .scoreboard {
        position: fixed; /* ou 'absolute' si vous préférez qu'il soit relatif au parent positionné */
        top: 0;
        left: 0;
        padding: 10px;
        background-color: rgb(18, 18, 18);
        border-right: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        margin-right: 50%;
        border-radius: 0px;
        z-index: 1000; /* Assurez-vous qu'il est au-dessus des autres éléments */
        width: auto; /* ou spécifiez une largeur si nécessaire */
        box-shadow: 0px 2px 5px rgba(0,0,0,0.1); /* Optionnel: ajoute de l'ombre pour le rendre plus visible */
    }

        .scoreboard h2 {
            font-size: 1.2rem;
            margin-bottom: 10px;
            text-align: center;
        }

        .scoreboard ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        .scoreboard li {
            padding: 2px;
            margin-bottom: 5px;
            font-size: 1rem;
        }

    .crown {
        color: gold;
        position: relative;
        top: -2px;
        margin-left: 5px;
    }

    .unfound {
        color: yellow;
    }

    .found {
        color: green;
    }

    .failed {
        color: red;
    }


    .sugest {
        color: white;
    }

    .timer, .response, .try {
        color: white;
    }

    .top-bar {
        position: fixed; /* ou 'absolute' si vous préférez qu'il soit relatif au parent positionné */
        top: 0;
        left: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }

    .volume-control {
        display: flex;
        align-items: center;
    }

        .volume-control label {
            margin-right: 30px;
            margin-top: 6px;
        }

    .volume-slider {
        width: 100px; /* Ajustez la largeur du slider ici */
    }

    @media (max-width: 600px) {
        .messages {
            width: 90%; /* Prendre toute la largeur de l'écran sur les petits appareils */
            max-height: 30vh; /* Définir une hauteur maximale */
            overflow-y: auto;
            bottom: 0; /* Positionner en bas de l'écran */
            top: auto; /* Désactiver le positionnement en haut */
            border-left: none; /* Enlever la bordure à gauche */
            box-shadow: 0px -2px 5px rgba(0,0,0,0.1); /* Ombre au-dessus pour séparer du contenu */
        }

        /* Cacher la barre de volume sur petits écrans */
        .volume-control {
            display: none;
        }

        /* Repositionner les scores */
        .scoreboard {
            margin-bottom: 20px; /* Ajouter de l'espace en dessous */
        }

        /* Ajuster le style du reste du contenu pour laisser de l'espace pour les scores */
        .game-room {
            padding-top: 70px; /* Donner de l'espace en haut pour les scores */
        }
    }

    .messages ul {
        list-style: none;
        padding: 0;
    }

    .settings-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #333;
        color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 10000;
    }

    .settings-content {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .ping-display {
        position: fixed;
        bottom: 10px; /* Positionné à 10px du bas de la page */
        left: 10px; /* Positionné à 10px du bord gauche */
        background-color: rgba(0, 0, 0, 0.8); /* Fond sombre avec opacité */
        color: white;
        padding: 5px 10px; /* Espacement interne */
        border-radius: 5px; /* Coins arrondis */
        font-size: 14px; /* Taille du texte */
        z-index: 1000; /* Assurez-vous que l'élément est au-dessus des autres */
    }

    .box-art {
        margin-top: 10px;
        max-width: 200px; /* Limite la largeur de l'image */
        max-height: 300px; /* Limite la hauteur de l'image */
        border-radius: 10px; /* Ajoute des coins arrondis */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ajoute une ombre */
    }

</style>
