<template>
    <div>
        <h1>Connexion</h1>
        <form @submit.prevent="login" class="form-container">
            <div class="input-group">
                <input v-model="credentials.username" type="text" placeholder="Username" required />
            </div>
            <div class="input-group">
                <input v-model="credentials.password" type="password" placeholder="Password" required />
            </div>
            <div class="input-group stay-signed-in">
                <input v-model="staySignedIn" type="checkbox" id="staySignedIn" />
                <label for="staySignedIn">Stay signed in</label>
            </div>
            <div class="input-group">
                <button type="submit" class="button">Login</button>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios';

    const apiClient = axios.create({
        baseURL: 'https://avgmq.net',
        withCredentials: true, // Enable sending cookies over CORS
        headers: {
            'Content-Type': 'application/json',
            // Vous pouvez ajouter ici des en-têtes ou des configurations supplémentaires
        },
    });

    export default {
        data() {
            return {
                credentials: {
                    username: '',
                    password: ''
                },
                staySignedIn: false // Ajout de l'état pour la case à cocher
            };
        },
        mounted() {
            // Vérifiez si le cookie "username" existe lorsque le composant est monté
            if (this.getUsernameFromCookie()) {
                this.$router.push({ name: 'RoomSelection' }); // Redirigez si l'utilisateur est déjà connecté
            }
        },
        methods: {
            getUsernameFromCookie() {
                const match = document.cookie.match(new RegExp('(^| )username=([^;]+)'));
                if (match) {
                    return match[2];
                }
                return null;
            },
            async login() {
                try {
                    const response = await apiClient.post('/api/login', this.credentials);
                    const { access_token } = response.data;
                    // Stocker le token
                    localStorage.setItem('access_token', access_token);
                    // Configurer l'intercepteur pour les futures requêtes
                    apiClient.interceptors.request.use(config => {
                        config.headers.Authorization = `Bearer ${access_token}`;
                        return config;
                    });
                    // Définir le cookie avec la durée appropriée
                    if (this.staySignedIn) {
                        document.cookie = `username=${this.credentials.username};path=/;max-age=${30 * 24 * 60 * 60}`;
                    } else {
                        document.cookie = `username=${this.credentials.username};path=/`;
                    }
                    // Redirection
                    this.$router.push({ name: 'RoomSelection' });
                } catch (error) {
                    // Gérer les erreurs de connexion
                    console.error('Erreur de connexion:', error);
                }
            }
        }
    };
</script>

<style scoped>
    .form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-group {
        margin-bottom: 10px;
    }

    .stay-signed-in {
        display: flex;
        align-items: center;
    }

        .stay-signed-in input {
            margin-right: 5px;
        }

    .button {
        padding: 10px 20px;
        background-color: #42b983;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

        .button:hover {
            background-color: #333;
        }
</style>
