<template>
    <div class="container">
        <div class="room-list-container">
            <h1>Open Rooms</h1>
            <ul>
                <li v-for="room in rooms" :key="room.id" @click="selectRoom(room)">
                    <span>{{ room.name }}</span>
                    <span v-if="room.requiresPassword">(Password Protected)</span>
                </li>
            </ul>
        </div>

        <div v-if="selectedRoom" class="password-form-container">
            <h2>Join Room: {{ selectedRoom.name }}</h2>
            <form @submit.prevent="joinRoom">
                <div v-if="selectedRoom.requiresPassword">
                    <input v-model="password" type="password" placeholder="Password" required />
                </div>
                <button type="submit">Join Room</button>
            </form>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    const apiClient = axios.create({
        baseURL: 'https://avgmq.net',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    export default {
        data() {
            return {
                rooms: [],
                selectedRoom: null,
                password: '',
                errorMessage: '',
                isLoading: false
            };
        },
        created() {
            this.fetchRooms();
        },
        methods: {
            fetchRooms() {
                apiClient.get('/api/open_rooms')
                    .then(response => {
                        this.rooms = response.data.rooms;
                    })
                    .catch(error => {
                        console.error('Error fetching rooms:', error);
                        this.errorMessage = 'Failed to load rooms. Please try again later.';
                    });
            },
            selectRoom(room) {
                this.selectedRoom = room;
                this.password = '';  // Réinitialiser le mot de passe
                this.errorMessage = '';
            },
            getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
                return '';
            },
            joinRoom() {
                const username = this.getCookie('username');
                if (!username) {
                    this.errorMessage = 'You must be logged in to join a room.';
                    return;
                }

                const joinDetails = {
                    roomId: this.selectedRoom.id,
                    password: this.password,
                    username: username
                };

                this.errorMessage = '';
                this.isLoading = true;
                apiClient.post('/api/join_private_room', joinDetails)
                    .then(response => {
                        console.log('Successfully joined the room:', response.data);
                        localStorage.setItem('room_token', response.data.access_token);
                        this.$router.push({ name: 'PrivateRoom', params: { roomId: joinDetails.roomId } });
                    })
                    .catch(error => {
                        console.error('Error joining room:', error);
                        this.errorMessage = 'Failed to join the room. Please check your details and try again.';
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }
    };
</script>

<style scoped>
    .room-list-container {
        width: 350px;
        margin: auto;
        padding: 20px;
        border: 1px solid black;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #333;
        color: #fff;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        padding: 10px;
        margin: 5px 0;
        background-color: #444;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
    }

        li:hover {
            background-color: #555;
        }

    .password-form-container {
        width: 350px;
        margin: 20px auto;
        padding: 20px;
        border: 1px solid black;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #333;
        color: #fff;
    }

    input[type="password"] {
        width: calc(100% - 20px);
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    button {
        width: calc(100% - 20px);
        padding: 10px;
        background-color: #42b983;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

        button:hover {
            background-color: #368a6d;
        }

    .error {
        color: #ff6347;
        margin-top: 10px;
    }
</style>
