<template>
    <div class="background-image"></div>
    <div class="room-container">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'App',
        methods: {
            goToLogin() {
                this.$router.push('/login');
            },
            goToRegister() {
                this.$router.push('/register');
            }
        }
    }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
    .background-image {
        position: fixed; /* L'image reste fixe */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('@/assets/background.jpg'); /* Remplacez par le chemin de votre image */
        background-size: cover; /* L'image couvre tout l'écran */
        background-position: center; /* Centre l'image */
        background-repeat: no-repeat; /* Pas de répétition de l'image */
        z-index: -1; /* Place l'image derrière tous les autres éléments */
    }

    @media (min-aspect-ratio: 21/9) {
        .background-image {
            background-image: url('@/assets/background_2.jpg'); /* Image optimisée pour 21:9 */
        }
    }

        body {
            background-color: rgb(18, 18, 18); /* Couleur de fond noire pour tout le corps du document */
            color: #fff; /* Texte de couleur blanche pour un bon contraste */
            margin: 0; /* Retire les marges par défaut pour éviter des espaces indésirables autour de la page */
            font-family: 'Your Preferred Font', sans-serif; /* Remplacez 'Your Preferred Font' par la police que vous souhaitez utiliser */
        }
        /* Assurez-vous que les autres éléments prennent en compte la couleur de fond et de texte par défaut définies */
        input, button, textarea, select {
            background-color: #222; /* Un fond légèrement différent pour les éléments de formulaire */
            color: #ddd; /* Texte légèrement plus clair pour les éléments de formulaire */
            border: 1px solid #444; /* Bordure pour les éléments de formulaire pour les distinguer sur fond noir */
        }

        /* Vous pouvez aussi spécifier un style pour les liens si nécessaire */
        a {
            color: #5599ff; /* Couleur des liens pour un bon contraste sur le fond noir */
            text-decoration: none; /* Optionnel : enlève le soulignement des liens */
        }

            a:hover {
                color: #bbddff; /* Couleur des liens au survol */
            }

        h1, h2, h3, h4, h5, h6, p, label {
            color: #fff;
            /* ... autres styles ... */
        }

        nav {
            padding: 30px;
        }

            nav a {
                font-weight: bold;
                color: #2c3e50;
            }

                nav a.router-link-exact-active {
                    color: #42b983;
                }

        button {
            margin: 20px;
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
        }
</style>
